import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  createContext,
  useContext,
} from 'react'
import { useRouter } from 'next/router'
import { useBroadcastInfo } from '@src/sharedBetweenApps/lib/utils/useBroadcastInfo'
import { extractPageNumber } from '../utils/url/extractPageNumber'

export type PushArgs = Parameters<ReturnType<typeof useRouter>['push']>[0]

const NavigationContext = createContext<{
  previousRoute?: string
  clear: () => void
  clearAndPush: (path: string) => void
  navigatePush: (args: PushArgs) => void
  navigateReplace: (path: string) => void
  navigateToParent: (parentPage?: number) => void
  navigateToPrevious: () => void
}>({
  clear: () => {},
  clearAndPush: () => {},
  navigatePush: () => {},
  navigateReplace: () => {},
  navigateToParent: () => {},
  navigateToPrevious: () => {},
})

export function NavigationContextProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [previousRoute, setPreviousRoute] = useState<string>('')
  const router = useRouter()
  const [currentPage, setCurrentPage] = useState<number>(
    extractPageNumber(router.asPath),
  )

  const { country, station } = useBroadcastInfo()

  const basePath = useCallback(() => {
    return `/${station}/${country}`
  }, [station, country])

  const [pages, setPages] = useState<number[]>([
    extractPageNumber(router.pathname),
  ])

  const handleBeforeHistoryChange = useCallback(
    (url: string) => {
      const [nextUrl] = url?.split('?') || []
      if (nextUrl !== router.asPath) {
        setPreviousRoute(router.asPath.replace(`${basePath()}/`, ''))
      }
    },
    [basePath, router.asPath],
  )

  const handleRouteChangeComplete = useCallback((path: string) => {
    const pageNumber = extractPageNumber(path)
    setCurrentPage(pageNumber)
    setPages((p) => {
      if (p.length > 0 && p[p.length - 1] === pageNumber) {
        // don't push current route if we are already there (e.g. hot reload)
        return p
      }
      return [...p, pageNumber]
    })
  }, [])

  useEffect(() => {
    router.events.on('beforeHistoryChange', handleBeforeHistoryChange)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return () => {
      router.events.off('beforeHistoryChange', handleBeforeHistoryChange)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [handleBeforeHistoryChange, handleRouteChangeComplete, router])

  const navigateReplace = useCallback(
    (path: string) => {
      setPages((p) => (p.length > 1 ? p.slice(0, p.length - 1) : []))
      // unstable_skipClientCache seems to be the only way to force a new GET request on a route change
      // @ref https://github.com/vercel/next.js/issues/35195
      router.push(`${basePath()}${path}`, undefined, {
        unstable_skipClientCache: true,
      })
    },
    [basePath, router],
  )

  const getUpdatedPages = (currentPages: number[], parentPage?: number) => {
    if (parentPage) {
      const idxOfLastParent = currentPages.lastIndexOf(parentPage)

      if (idxOfLastParent !== -1) {
        return currentPages.slice(0, idxOfLastParent + 1)
      }
    }
    return []
  }

  const navigateToParent = useCallback(
    (parentPage?: number) => {
      const newPages = getUpdatedPages(pages, parentPage)
      const isRootPage = currentPage === 100

      const wasVisitedBefore = (n: number): boolean =>
        pages.lastIndexOf(n) !== -1

      const isSportsPage = currentPage > 200 && currentPage < 300
      const sportsOverviewVisitedBefore = wasVisitedBefore(200)
      const directParentVisited = newPages.length > 0
      const pagesWithBackToParent = [235] // add more pages here if needed
      const shouldDirectlyGoToParent =
        parentPage &&
        pagesWithBackToParent.some((page) => wasVisitedBefore(page))

      setPages(newPages)

      if (
        isSportsPage &&
        sportsOverviewVisitedBefore &&
        !directParentVisited &&
        !shouldDirectlyGoToParent
      ) {
        // Sports pages have multiple parents (e.g. 213 parent is 210, and 210
        // parent is 200), and we might navigate from 200 to 213 directly and
        // then want go back to 200, which would not work, as 200 is not the
        // direct parent.
        navigateReplace(`/200`)
      } else if (shouldDirectlyGoToParent) {
        navigateReplace(`/${parentPage}`)
      } else if (!isRootPage) {
        navigateReplace(
          directParentVisited ? `/${newPages[newPages.length - 1]}` : `/100`,
        )
      }
    },
    [currentPage, navigateReplace, pages],
  )

  const navigatePush = useCallback(
    (args: PushArgs) => {
      if (typeof args === 'string') {
        // add leading slash if not present
        const pathname = args.charAt(0) === '/' ? args : `/${args}`
        router.push(`${basePath()}${pathname}`, undefined, {
          unstable_skipClientCache: true,
        })
      }
      if (typeof args === 'object') {
        const { pathname } = args
        args.pathname = `${basePath()}${pathname}`
        router.push(args, undefined, { unstable_skipClientCache: true })
      }
    },
    [basePath, router],
  )

  const navigateToPrevious = useCallback(() => {
    if (previousRoute.length === 0) {
      // fallback when previousRoute is not set, e.g. app start via deeplink
      navigateReplace('/100')
    } else {
      navigateReplace(`/${previousRoute}`)
    }
  }, [navigateReplace, previousRoute])

  const clear = useCallback(() => {
    setPages([])
  }, [])

  const clearAndPush = useCallback(
    (path: string) => {
      clear()
      navigatePush(`${path}`)
    },
    [clear, navigatePush],
  )

  return (
    <NavigationContext.Provider
      value={useMemo(
        () => ({
          previousRoute,
          clear,
          clearAndPush,
          navigatePush,
          navigateReplace,
          navigateToParent,
          navigateToPrevious,
        }),
        [
          previousRoute,
          clear,
          clearAndPush,
          navigatePush,
          navigateReplace,
          navigateToParent,
          navigateToPrevious,
        ],
      )}
    >
      {children}
    </NavigationContext.Provider>
  )
}

export default function useNavigationContext() {
  return useContext(NavigationContext)
}
