import React, { useState } from 'react'
import '../sharedBetweenApps/lib/polyfills'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { BetterFocusContextProvider } from '@cbc/hbbtv-react-better-focus'
import { ThemeProvider } from 'styled-components'
import defaultTheme from '../themes/default'
import ntvTheme from '../themes/ntv'
import ThemeGlobalStyle from '../themes/ThemeGlobalStyle'
import Background from '@components/shared/Background'
import GlobalHotKeys from '../sharedBetweenApps/lib/utils/GlobalHotKeys'
import useInitializeOipfApplication from '@src/sharedBetweenApps/lib/hooks/useInitializeOipfApplication'
import PlayerConfigurationProvider from '@src/sharedBetweenApps/lib/playout/PlayerConfigurationProvider'
import { NavigationContextProvider } from '@lib/hooks/useNavigationContext'
import BugsnagErrorBoundary from '../sharedBetweenApps/lib/bugsnag/BugsnagErrorBoundary'
import { BugsnagBoundary } from '../sharedBetweenApps/lib/bugsnag/bugsnag'
import ErrorBoundary from '@components/base/ErrorBoundary'
import { useBroadcastInfo } from '@src/sharedBetweenApps/lib/utils/useBroadcastInfo'

function TheApp({ Component, pageProps }: AppProps) {
  const [initialized, setInitialized] = useState(false)
  useInitializeOipfApplication(setInitialized)
  const { station } = useBroadcastInfo()
  const theme = station === 'ntv' ? ntvTheme : defaultTheme

  if (typeof window === 'undefined') {
    React.useLayoutEffect = React.useEffect
  }

  return (
    <BugsnagErrorBoundary Boundary={BugsnagBoundary}>
      <PlayerConfigurationProvider>
        <NavigationContextProvider>
          <BetterFocusContextProvider>
            <Head>
              <meta
                httpEquiv="content-type"
                content="application/vnd.hbbtv.xhtml+xml"
              />
            </Head>
            <ThemeProvider theme={theme}>
              <ThemeGlobalStyle />
              <Background />
              <ErrorBoundary>
                <Component {...pageProps} initialized={initialized} />
              </ErrorBoundary>
            </ThemeProvider>
            <GlobalHotKeys />
          </BetterFocusContextProvider>
        </NavigationContextProvider>
      </PlayerConfigurationProvider>
    </BugsnagErrorBoundary>
  )
}
export default TheApp
