import { Metadata } from '@src/sharedBetweenApps/lib/tracking/trackingTypes'
import {
  getConsent,
  hasConnectorIdConsent,
} from '@src/sharedBetweenApps/lib/utils/consent'
import { getSmartnsIdFromCookie } from '@src/sharedBetweenApps/lib/utils/device'

type TracktorEventType = 'view' | 'click' | 'media'

export const maybeTrackTracktorEvent = (
  metadata: Metadata,
  offer: string,
  eventType: TracktorEventType,
  videoId?: string,
) => {
  if (!hasConnectorIdConsent()) {
    return
  }
  const { agof, ivw } = metadata
  const st = offer

  const trackerParameter = {
    timestamp: new Date(),
    status_code: 'ok',
    id: getSmartnsIdFromCookie(),
    tags: {
      ev: '',
      st,
      cp: agof,
      co: ivw,
      cs: '',
      local_time: new Date(),
    },
    user_agent: navigator.userAgent,
    measure_type: '',
    locallist_check: '',
    useragent_whitelist_check: '',
  }

  if (typeof window !== 'undefined') {
    // @ts-expect-error window._itracker2 is not defined
    const iTracker2 = window._itracker2 || {}

    iTracker2.consent = getConsent()
    switch (eventType) {
      case 'view':
        iTracker2.logViewEvent && iTracker2.logViewEvent(ivw, trackerParameter)
        break
      case 'click':
        iTracker2.logClickEvent &&
          iTracker2.logClickEvent(ivw, trackerParameter)
        break
      case 'media':
        iTracker2.logMediaEvent &&
          iTracker2.logMediaEvent('play', 0, videoId, trackerParameter)
        break
      default:
        console.error('Unknown Tracktor tracking event type')
        return
    }

    try {
      iTracker2.transmitNow && iTracker2.transmitNow()
    } catch (error) {
      console.error('Failed to track Tracktor: ', error)
    }
  } else {
    console.warn('Tracktor is not loaded or iTracker2 is not available')
  }
}
