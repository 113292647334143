import { useRouter } from 'next/router'
import {
  Countries,
  Country,
  ParsedBroadcastInfo,
  Station,
  Stations,
} from '@src/sharedBetweenApps/lib/utils/station'
import { GetServerSidePropsContext } from 'next/types'

function extracted(
  country: string | string[] | undefined,
  station: string | string[] | undefined,
  asPath: string,
): ParsedBroadcastInfo {
  let isValid = true
  if (country && station) {
    if (
      Countries.includes(country as Country) &&
      Stations.includes(station as Station)
    ) {
      return {
        country: country as Country,
        station: station as Station,
        wasValid: true,
      }
    }
    console.warn(
      `The parameters(station='${station} and country=${country}) are not valid. Using fallbacks.`,
    )
    isValid = false
  }

  // fallback to url e.g. for 404 because there is no router query object present
  const routeParts = asPath.split('/').filter(Boolean)
  const urlStation = Stations.find((x) => x === routeParts[0])
  const urlCountry = Countries.find((x) => x === routeParts[1])

  if (urlCountry && urlStation) {
    return {
      country: urlCountry,
      station: urlStation,
      wasValid: isValid,
    }
  }

  const broadcastDefault: Station = Stations.includes(
    process.env.NEXT_PUBLIC_BROADCAST_DEFAULT as Station,
  )
    ? (process.env.NEXT_PUBLIC_BROADCAST_DEFAULT as Station)
    : 'rtl'

  return {
    country: urlCountry ?? 'de',
    station: urlStation ?? broadcastDefault,
    wasValid: false,
  }
}

// As a hook for use in react apps
export function useBroadcastInfo(): ParsedBroadcastInfo {
  const router = useRouter()
  const {
    query: { country, station },
    asPath,
  } = router
  return extracted(country, station, asPath)
}

// As a normal function for use in getServerSideProps()
export function extractBroadcastInfoForServerSide(
  context: GetServerSidePropsContext<any>,
): ParsedBroadcastInfo {
  return extracted(
    context.params.country,
    context.params.station,
    context.resolvedUrl,
  )
}

// As a normal function based on a URL
export function extractBroadcastInfoFromUrl(
  pathname: string,
): ParsedBroadcastInfo {
  return extracted(undefined, undefined, pathname)
}
