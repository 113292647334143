import { TrackingConfiguration } from '@src/sharedBetweenApps/lib/tracking/useEventTracking'

export function getOffer(
  trackingConfig: TrackingConfiguration,
  station: string,
) {
  const { offer } = trackingConfig
  let offerStr = offer[station]
  if (!offerStr) {
    offerStr = 'ctvrtlde'
    console.error(
      `No offer for station "${station}" found (check NEXT_PUBLIC_TRACKING_CONFIGURATION), ` +
        `using fallback value "${offerStr}".`,
    )
  }
  return offerStr
}
