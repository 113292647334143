import ReactGA from 'react-ga4'
import { useCallback, useMemo, useRef } from 'react'
import { useRouter } from 'next/router'
import { hasTrackingConsent } from '@src/sharedBetweenApps/lib/utils/consent'
import { useBroadcastInfo } from '@src/sharedBetweenApps/lib/utils/useBroadcastInfo'

type GoogleTagEvent =
  | 'page_view'
  | 'teaser_clicked'
  | 'video_content_ended'
  | 'video_content_started'
  | 'video_started'

interface GoogleTagPageViewParams {
  page_location: string
}

interface GoogleTagTeaserClickedParams {
  content_id: string
  content_title: string
  content_type: 'video'
  page_location: string
}

export interface GoogleTagVideoTrackingParams {
  media_first_publication_date?: string
  media_id: string
  media_tags: string
  media_title: string
  page_location: string
}

export function useGoogleTag() {
  const isServerSide = typeof window === 'undefined'
  const isGoogleTagTrackingActive = !isServerSide && hasTrackingConsent()
  const { station: tv_channel } = useBroadcastInfo()

  const commonFields = useMemo(
    () => ({
      hbbtv_app: `${tv_channel}_digitaltext_v2`,
      tv_channel,
    }),
    [tv_channel],
  )

  // must be called after each route change, otherwise send_page_view is not working
  // unless Enhanced Measurements are disabled in GA4 settings.
  // @see https://github.com/codler/react-ga4/issues/27
  const initializeGoogleTag = useCallback(() => {
    if (isGoogleTagTrackingActive) {
      ReactGA.initialize('G-DXWHKVGN7Z', {
        gtagOptions: {
          send_page_view: false,
        },
      })
    }
  }, [isGoogleTagTrackingActive])

  const lastTrackedRoute = useRef<string | null>(null)
  const router = useRouter()

  const trackEvent = useCallback(
    (
      eventName: GoogleTagEvent,
      params:
        | GoogleTagPageViewParams
        | GoogleTagTeaserClickedParams
        | GoogleTagVideoTrackingParams,
    ) => {
      if (isGoogleTagTrackingActive) {
        initializeGoogleTag()
        ReactGA.gtag('event', eventName, { ...commonFields, ...params })
      }
    },
    [isGoogleTagTrackingActive, initializeGoogleTag, commonFields],
  )

  const trackPageView = useCallback(
    (ivw: string): void => {
      if (lastTrackedRoute.current !== router.asPath) {
        lastTrackedRoute.current = router.asPath
        trackEvent('page_view', { page_location: ivw })
      }
    },
    [router.asPath, trackEvent],
  )

  const trackTeaserClicked = useCallback(
    (content_id: string, content_title: string, ivw: string) =>
      trackEvent('teaser_clicked', {
        content_id,
        content_title,
        content_type: 'video',
        page_location: ivw,
      }),
    [trackEvent],
  )

  const trackVideoStarted = useCallback(
    (params: GoogleTagVideoTrackingParams) =>
      trackEvent('video_started', params),
    [trackEvent],
  )

  const trackContentStarted = useCallback(
    (params: GoogleTagVideoTrackingParams) =>
      trackEvent('video_content_started', params),
    [trackEvent],
  )

  const trackContentEnded = useCallback(
    (params: GoogleTagVideoTrackingParams) =>
      trackEvent('video_content_ended', params),
    [trackEvent],
  )

  return useMemo(
    () => ({
      trackPageView,
      trackTeaserClicked,
      trackVideoStarted,
      trackContentStarted,
      trackContentEnded,
    }),
    [
      trackPageView,
      trackTeaserClicked,
      trackVideoStarted,
      trackContentStarted,
      trackContentEnded,
    ],
  )
}
