import defaultTheme from './default'

const ntvRed = '#dc0028'

const ntvTheme = {
  ...defaultTheme,
  name: 'ntv',
  palette: {
    ...defaultTheme.palette,
    primary: {
      ...defaultTheme.palette.primary,
      active: ntvRed,
      textActive: 'white',
    },
  },
}

export default ntvTheme
export type NtvTheme = typeof ntvTheme
