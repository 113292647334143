import Cookies from 'universal-cookie'
import { getItem } from './localStorage'

const SMART_UUID_COOKIE = 'smartns_uuid'
const DEVICE_CAPABILITIES_COOKIE = 'device_capabilities'

type TlsVersion = '1.0' | '1.2'

export interface DeviceCapabilities {
  capabilities: {
    supportedPlatforms: string[]
    maximumTlsVersion: TlsVersion
  }
}

export function getSmartnsIdFromCookie(): string | undefined {
  const cookies = new Cookies()
  return cookies.get(SMART_UUID_COOKIE)
}

export function getDeviceCapabilitiesFromBrowserStorage():
  | DeviceCapabilities
  | undefined {
  return getItem(DEVICE_CAPABILITIES_COOKIE)
}

export function isSupportedPlatform(
  deviceCapabilities: DeviceCapabilities,
  platform: string,
): boolean {
  return (
    deviceCapabilities.capabilities.supportedPlatforms.indexOf(platform) !== -1
  )
}

export function isTls12Supported() {
  const capabilities = getDeviceCapabilitiesFromBrowserStorage()?.capabilities
  return capabilities?.maximumTlsVersion === '1.2'
}
