export const Countries = ['de', 'at', 'ch'] as const
export type Country = (typeof Countries)[number]

export const Stations = [
  'freenetrtl',
  'freenetvox',
  'nitro',
  'ntv',
  'rtl',
  'rtlplus',
  'rtluhd',
  'vox',
  'voxup',
] as const
export type Station = (typeof Stations)[number]

export interface BroadcastInfo {
  station: Station
  country: Country
}

export interface ParsedBroadcastInfo extends BroadcastInfo {
  wasValid: boolean
}
