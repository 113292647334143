import '@vendors/iam'
import { Metadata } from '@src/sharedBetweenApps/lib/tracking/trackingTypes'
import { bugsnag } from '@src/sharedBetweenApps/lib/bugsnag'
import { hasTrackingConsent } from '@src/sharedBetweenApps/lib/utils/consent'

export const CT = '0000180141'

export function maybeTrackInfOnline(metadata: Metadata, offer: string) {
  if (!hasTrackingConsent()) {
    return
  }
  const iamTrackingData = {
    co: metadata.ivw, // comment
    cp: metadata.agof, // code
    ct: CT,
    st: offer, // site/domain
  }
  const iamSzmVersion = 2

  try {
    // @ts-expect-error TS(2339): Property 'iom' does not exist on type 'Window & typeof globalThis'.
    window.iom.c(iamTrackingData, iamSzmVersion)
  } catch (error) {
    console.log('failed to track inf-online', error)
    bugsnag?.notify(error)
  }
}
