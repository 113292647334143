import { useCallback } from 'react'
import {
  FocusableWidget,
  useBetterFocusApi,
  useFocusEffect,
} from '@cbc/hbbtv-react-better-focus'
import styled from 'styled-components'
import { useHideBroadcast } from './OipfApplication/broadcast'
import { useBroadcastInfo } from '@src/sharedBetweenApps/lib/utils/useBroadcastInfo'
import usePageTracking from '@src/sharedBetweenApps/lib/tracking/usePageTracking'
import { useGoogleTag } from '@src/sharedBetweenApps/lib/tracking/useGoogleTag'

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const BUTTON_WIDTH = 220
const IMAGE_WIDTH = 180

const BackButtonContainer = styled(FocusableWidget)`
  position: relative;
  color: white;
  width: ${BUTTON_WIDTH}px;
  font-size: 18px;
  margin: 30px auto;

  &:focus,
  &:focus > * {
    outline: 0;
    background: white;
    color: black;
  }
`

const BackButtonText = styled.span`
  float: left;
  display: block;
  text-align: center;
  line-height: 30px;
  height: 30px;
  font-size: 16px;
  width: ${BUTTON_WIDTH}px;
`

const ErrorImage = styled.img`
  width: ${IMAGE_WIDTH}px;
  height: auto;
  margin: 20px auto;
  display: block;
  z-index: 1001;
`
const ErrorText = styled.div`
  color: white;
  margin: 0 auto;
  width: 400px;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  z-index: 1;
`

export default function ErrorDialog() {
  useHideBroadcast()
  const focusApi = useBetterFocusApi()
  const focusPath = '404'
  const errorImage = '/error-tv.png'

  const broadcastInfo = useBroadcastInfo()
  const ivw = `/${broadcastInfo.station}_digitaltext_v2/error`

  const { trackPageView } = useGoogleTag()
  trackPageView(ivw)
  usePageTracking({
    agof: 'dbrhcwf_tve_digitaltext',
    ivw,
  })

  useFocusEffect((focused) => {
    if (focused !== `${focusPath}/back`) {
      focusApi.focusItem(`${focusPath}/back`)
    }
  }, [])

  const onOkPressed = useCallback(
    (event: KeyboardEvent) => {
      event.stopPropagation()
      window.location.replace(
        `/${broadcastInfo.station}/${broadcastInfo.country}/100`,
      )
    },
    [broadcastInfo],
  )

  return (
    <div style={{ height: 720 }}>
      <Container>
        <ErrorText>
          Upps... Da ist etwas schief gegangen...
          <br /> Entschuldigung!
        </ErrorText>
        <ErrorImage src={errorImage} loading="lazy" />
        <BackButtonContainer
          focusPath={`${focusPath}/back`}
          onOkPressed={onOkPressed}
        >
          <div>
            <BackButtonText>Zur Startseite</BackButtonText>
          </div>
        </BackButtonContainer>
      </Container>
      {/*
    It is possible that the HTML of the error page is cached, but the Javascript bundles are not.
    To mitigate this problem, we use an inline javascript that simply reloads the page on keydown.
    */}
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
window.addEventListener("keydown", (event) => {
  if(event.key === 'Enter') {
    location.reload();
  }
});`,
        }}
      ></script>
    </div>
  )
}
