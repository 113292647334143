// This file contains polyfills that are used for every portal by default.
// They will be bundled in the polyfills webpack chunk.
// To overwrite the polyfills for a specific portal, create a polyfills.js file in
// the portal directory. It will replace this file while building (e.g. for LG).

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable()
  window.Promise = require('promise/lib/es6-extensions.js')
}

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign')

require('raf/polyfill')

require('core-js/es/weak-map')
require('core-js/es/map')
require('core-js/es/set')
require('core-js/es/array')
require('core-js/es/array/iterator')
require('core-js/es/symbol')
require('core-js/es/string/includes')
require('core-js/es/string/starts-with')
require('core-js/es/string/ends-with')
require('core-js/es/string/pad-start')
require('core-js/es/object/create')
require('core-js/es/object/freeze')
require('core-js/es/object/entries')
require('core-js/es/object/values')
require('core-js/features/global-this')

require('@cbc/hbbtv-keyboardevent-key-polyfill').polyfill()

if (typeof window !== 'undefined') {
  // panasonic 2016 doesn't support FileReader correctly, so we have to polyfill fetch
  require('@cbc/hbbtv-fetch-polyfill')
}

// fix crash on old browsers that do not allow re-defining Function.name
// (e.g. Samsung 2016, Chrome 36)
const defineProperty = Object.defineProperty
Object.defineProperty = (obj, property, attributes) => {
  if (property === 'name') {
    // Pre ES2015 non standard implementation, "Function.name" is non configurable field
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function/name
    try {
      return defineProperty(obj, property, attributes)
    } catch (_e) {
      return obj
    }
  } else {
    return defineProperty(obj, property, attributes)
  }
}

export {}
